import type { FormResponse } from "@/lib/form";
import type { MaybePromise } from "@/lib/util";
import { useToast } from "@chakra-ui/react";
import {
  FormikContext,
  type FormikErrors,
  type FormikHelpers,
  type FormikTouched,
  useFormik,
} from "formik";
import { withZodSchema } from "formik-validator-zod";
import type React from "react";
import { useCallback } from "react";
import type { z } from "zod";
import { useToastRefetcher } from "../toast-provider";
import { useReCaptcha } from "next-recaptcha-v3";
import { usePathname } from "next/navigation";

function flattenErrors(
  errors: { path: (string | number)[]; message: string }[],
) {
  const result: Record<string, string> = {};
  for (const error of errors) {
    result[error.path.join(".")] = error.message;
  }
  return result;
}

export type FormSubmitFunction<Values, Result> = (
  values: Values,
  // biome-ignore lint/suspicious/noConfusingVoidType: typescript complains when I do MaybePromise<...> | MaybePromise<void>
) => MaybePromise<FormResponse<Result> | void>;

export type FormSubmitSuccessFunction<Values, Func> = (args: {
  values: Values;
  result: Func extends FormSubmitFunction<Values, infer Result>
    ? Result
    : never;
}) => MaybePromise<void>;

export type FormSubmitErrorFunction<Values> = (args: {
  values: Values;
}) => MaybePromise<void>;

export interface FormProps<
  Schema extends z.ZodSchema,
  Func extends FormSubmitFunction<z.input<Schema>, any> = FormSubmitFunction<
    z.input<Schema>,
    any
  >,
> {
  initialValues: z.input<Schema>;
  schema: Schema;
  onSubmit: Func;
  onSubmitSuccess?: FormSubmitSuccessFunction<z.input<Schema>, Func>;
  onSubmitError?: FormSubmitErrorFunction<z.input<Schema>>;
  children:
    | React.ReactNode
    | ((props: {
        errors: FormikErrors<z.infer<Schema>>;
        touched: FormikTouched<z.infer<Schema>>;
        values: z.input<Schema>;
        submit: () => void;
        isSubmitting: boolean;
      }) => React.ReactNode);
  enableReinitialize?: boolean;
  enableToasts?: boolean;
  enableCaptcha?: boolean;
}

export function Form<
  Schema extends z.ZodSchema,
  Func extends FormSubmitFunction<z.input<Schema>, any>,
>({
  initialValues,
  schema,
  onSubmit: onSubmitProp,
  onSubmitSuccess,
  onSubmitError,
  children,
  enableReinitialize,
  enableToasts,
  enableCaptcha,
}: FormProps<Schema, Func>) {
  const pathname = usePathname();
  const refetchToasts = useToastRefetcher();
  const toast = useToast();
  const { executeRecaptcha } = useReCaptcha();

  const onSubmit = useCallback(
    async (
      values: z.input<Schema>,
      helpers: FormikHelpers<z.input<Schema>>,
    ) => {
      if (enableCaptcha) {
        try {
          // get token from recaptcha
          const token = await executeRecaptcha(pathname);

          // apply to form values
          values.captcha = token;
        } catch {
          toast({
            status: "error",
            title: "Invalid captcha response!",
          });
          return;
        }
      }

      const result = await onSubmitProp(values);

      if (result && "success" in result) {
        if (result.success) {
          if (result.reset) {
            helpers.resetForm();
          }

          if ("result" in result) {
            await onSubmitSuccess?.({ values, result: result.result });
          } else {
            await onSubmitSuccess?.({ values } as Parameters<
              FormSubmitSuccessFunction<z.input<Schema>, Func>
            >[0]);
          }
        } else {
          if (result.errors) {
            helpers.setErrors(
              flattenErrors(result.errors) as FormikErrors<z.infer<Schema>>,
            );

            const captchaError = result.errors.find(
              (error) => error.path.join(".") === "captcha",
            );

            if (captchaError && enableCaptcha) {
              toast({ status: "error", title: captchaError.message });
            }
          }

          await onSubmitError?.({ values });
        }
      }

      if (enableToasts) {
        refetchToasts();
      }
    },
    [
      onSubmitProp,
      enableCaptcha,
      enableToasts,
      refetchToasts,
      toast,
      onSubmitSuccess,
      onSubmitError,
      pathname,
      executeRecaptcha,
    ],
  );

  const formik = useFormik({
    enableReinitialize,
    validate: withZodSchema(schema),
    initialValues,
    onSubmit,
  });

  return (
    <FormikContext.Provider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {typeof children === "function"
          ? children({
              errors: formik.errors,
              touched: formik.touched,
              values: formik.values,
              submit: () => formik.handleSubmit(),
              isSubmitting: formik.isSubmitting,
            })
          : children}
      </form>
    </FormikContext.Provider>
  );
}
